import React, { useEffect, useState } from 'react';
import { Input, Menu, Modal, Popover } from 'antd';
import { useAtom, useSetAtom } from 'jotai';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import utils from '@src/utils/utils';
import { themeCurrentThemeAtom, themeMobileNavAtom } from '@src/store/theme';
import EllipsisDropdown from '@components/shared/EllipsisDropdown';
import { AddCircleOutline, IcoAi } from '@src/assets/svg';
import {
  AiRealtimeKeywordItem,
  BasicResponsePersonalSettings,
  BinderEntity,
  PersonalSettings,
  TeambinderAiService,
  TeambinderApiService,
} from '@src/api';
import useLogout from '@src/hooks/auth/useLogout';
import { KAKAO_REDIRECT_URL } from '@src/constants/constant';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import RealtimeKeywordModal from '@src/views/realtimeKeyword/RealtimeKeywordModal';
import { useQuery } from '@tanstack/react-query';

const { confirm } = Modal;

const RealtimeKeywordList = () => {
  const logout = useLogout();
  const [showAddKeywordModal, setShowAddKeywordModal] = useState(false);
  const setMobileNav = useSetAtom(themeMobileNavAtom);
  const [currentTheme, setCurrentTheme] = useAtom(themeCurrentThemeAtom);
  const isMobile = utils.isMobile();
  const closeMobileNav = () => {
    if (isMobile) {
      setMobileNav(false);
    }
  };

  const [settings, setSettings] = useState<PersonalSettings>();
  useEffect(() => {
    TeambinderApiService.getPersonalSettings().then((res) => {
      if (res.data) {
        setSettings(res.data);
      }
    });
  }, []);

  const [keywords, setKeywords] = useState<
    (AiRealtimeKeywordItem | { type: 'placeholder' })[]
  >([]);
  const fetchKeywords = async () => {
    try {
      const res = await TeambinderAiService.getAllAiRealtimeKeyword();
      if (res.data?.length === 0) {
        setKeywords([{ type: 'placeholder' }]);
      } else if (res.data) {
        setKeywords(res.data);
      }
      if (
        location.pathname === '/app/realtime' &&
        !res?.data?.find((item) => item.keywordId === keywordId)
      ) {
        navigate(`/app/realtime/new`);
      }
    } catch (e) {
      if ([401, 500].includes((e as any).status)) {
        logout();
        const authRedirecrtUrl = KAKAO_REDIRECT_URL;
        window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=7fa35d903c130394963af980d96edd38&redirect_uri=${authRedirecrtUrl}&response_type=code&prompt=login`;
      }
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    fetchKeywords();
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const keywordId = searchParams.get('id');

  const removeKeywordList = (index: number) => {
    confirm({
      title: '정말 삭제하시겠습니까?',
      async onOk() {
        await TeambinderAiService.deleteAiRealtimeKeyword({
          keywordId: (keywords[index] as AiRealtimeKeywordItem).keywordId!,
        });
        fetchKeywords();
      },
      onCancel() {
        return null;
      },
      okText: '삭제',
      cancelText: '취소',
    });
  };

  // const { mutate: reorderKeyword } = useMutation(
  //   TeambinderApiService.reorderBinder,
  //   {
  //     onSuccess: () => fetchKeywords(),
  //   },
  // );

  // const onDragEnd = (result: DropResult) => {
  //   const source = result.source;
  //   const destination = result.destination;

  //   if (
  //     source.droppableId === destination.droppableId &&
  //     source.index === destination.index
  //   ) {
  //     return;
  //   }

  //   let targetItemId, prevItemId;
  //   if (source.index < destination.index) {
  //     targetItemId = binders[source.index]?.binderId;
  //     prevItemId = binders[destination.index]?.binderId;
  //   } else {
  //     targetItemId = binders[source.index]?.binderId;
  //     prevItemId =
  //       destination.index === 0 ? '' : binders[destination.index - 1]?.binderId;
  //   }
  //   if (!targetItemId) {
  //     return;
  //   }

  //   // 1. 먼저 로컬 상태를 변경
  //   const newList = [...binders];
  //   const [destList] = newList.splice(source.index, 1);
  //   newList.splice(destination.index, 0, destList);
  //   setBinders(newList);

  //   // 2. 서버에 변경사항 반영
  //   reorderKeyword({
  //     targetBinderId: targetItemId,
  //     prevBinderId: prevItemId,
  //   });
  // };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        marginBottom: '16px',
      }}
    >
      <button
        className="title"
        style={{
          border: 'none',
          backgroundColor: 'inherit',
          fontSize: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '8px',
          paddingRight: '17px',
          paddingLeft: '12px',
          fontWeight: 700,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <IcoAi />
          AI 실시간 감지
        </div>{' '}
        <AddCircleOutline
          onClick={() => {
            if (
              (settings?.quota?.aiAnalysis?.total || 0) <=
              (settings?.quota?.aiAnalysis?.used || 0)
            ) {
              confirm({
                title: (
                  <div>
                    AI 컨텐츠 분석 횟수를 모두 소진하였습니다.
                    <br />
                    나에게 맞는 플랜을 찾아보세요!
                  </div>
                ),
                okText: '플랜 보기',
                onOk() {
                  window.location.href = '/app/my?tab=2';
                },
                cancelText: '취소',
              });
            } else {
              setShowAddKeywordModal(true);
            }
          }}
          style={{
            cursor: 'pointer',
          }}
        />
      </button>
      <DragDropContext onDragEnd={() => {}}>
        {/* onDragEnd={(result) => onDragEnd(result)}> */}
        <Droppable droppableId="realtime-keywords">
          {(provided) => {
            return (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  minHeight: `${keywords.length * 40}px`,
                }}
              >
                {keywords.map((item, index) => {
                  if ('type' in item) {
                    const isActive =
                      location.pathname.startsWith('/app/realtime/new');
                    return (
                      <div
                        style={{
                          minHeight: '40px',
                          width: '100%',
                          fontSize: '16px',
                          padding: '4px 8px 4px 30px',
                          cursor: 'pointer',
                          display: 'flex',
                          fontWeight: 500,
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          border: 'none',
                          backgroundColor: isActive ? '#f3f3f3' : 'inherit',
                          borderRight: isActive
                            ? '3px solid #4403b5'
                            : '3px solid transparent',
                          color:
                            isActive && currentTheme === 'dark'
                              ? 'black'
                              : 'inherit',
                        }}
                        onClick={() => {
                          navigate(`/app/realtime/new`);
                          closeMobileNav();
                        }}
                      >
                        새 키워드
                      </div>
                    );
                  }

                  const isActive =
                    item.keywordId === keywordId &&
                    location.pathname === '/app/realtime';
                  return (
                    <Draggable
                      draggableId={item.keywordId!}
                      index={index}
                      key={item.keywordId}
                    >
                      {(_provided, snapshot) => (
                        <div
                          ref={_provided.innerRef}
                          {..._provided.draggableProps}
                          {..._provided.dragHandleProps}
                        >
                          <Popover
                            mouseEnterDelay={1}
                            // content={
                            //   <div>
                            //     마우스를 드래그하여 키워드 순서를 변경할 수
                            //     있습니다.
                            //   </div>
                            // }
                            trigger="hover"
                          >
                            <div
                              key={item.keywordId}
                              style={{
                                width: '100%',
                                fontSize: '16px',
                                padding: '4px 8px 4px 30px',
                                cursor: 'pointer',
                                display: 'flex',
                                fontWeight: 500,
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                border: 'none',
                                backgroundColor:
                                  isActive || snapshot.isDragging
                                    ? '#f3f3f3'
                                    : 'inherit',
                                borderRight: isActive
                                  ? '3px solid #4403b5'
                                  : '3px solid transparent',
                                color:
                                  isActive && currentTheme === 'dark'
                                    ? 'black'
                                    : 'inherit',
                              }}
                              role="presentation"
                              onClick={() => {
                                if (item.keywordId) {
                                  navigate(
                                    `/app/realtime?id=${item.keywordId}`,
                                  );
                                  closeMobileNav();
                                }
                              }}
                            >
                              {item.keyword}
                              <EllipsisDropdown
                                menu={
                                  <Menu
                                    items={[
                                      {
                                        label: '삭제',
                                        key: 'remove',
                                        icon: null,
                                        onClick: (e) => {
                                          e.domEvent.stopPropagation();
                                          removeKeywordList(index);
                                        },
                                      },
                                    ]}
                                  />
                                }
                              />
                            </div>
                          </Popover>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                <div style={{ display: 'none' }}>{provided.placeholder}</div>
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
      {showAddKeywordModal && (
        <RealtimeKeywordModal onClose={() => setShowAddKeywordModal(false)} />
      )}
    </div>
  );
};

export default RealtimeKeywordList;
