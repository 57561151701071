import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M11 13H5v-2h6V5h2v6h6v2h-6v6h-2v-6Z" fill={props.fill} />
  </svg>
);
export default SvgIcoAdd;
