import { TeambinderAiService, TeambinderApiService } from '@src/api';
import { IcoAdd, IcoClose, IcoCloseCircle } from '@src/assets/svg';
import { Input, Modal } from 'antd';
import { useState } from 'react';
import { useNavigate, useNavigation } from 'react-router-dom';
import AddRealtimeKeywordDescModal from './AddRealtimeKeywordDescModal';
import { useQuery } from '@tanstack/react-query';

const { info } = Modal;

interface Prop {
  onClose: () => void;
  defaultData?: {
    keywordId: string;
    keyword: string;
    synonyms: string[];
    exclusions: string[];
  };
}
const RealtimeKeywordModal = ({ onClose, defaultData }: Prop) => {
  const [keyword, setKeyword] = useState(defaultData?.keyword || '');

  const [synonym, setSynonym] = useState('');
  const [synonyms, setSynonyms] = useState<string[]>(
    defaultData?.synonyms || [],
  );

  const [exclusion, setExclusion] = useState('');
  const [exclusions, setExclusions] = useState<string[]>(
    defaultData?.exclusions || [],
  );

  const navigate = useNavigate();

  const [isDescModalOpen, setIsDescModalOpen] = useState(false);

  const addSynonymOrExclusion = (
    type: 'synonym' | 'exclusion',
    keyword: string,
  ) => {
    if (type === 'synonym') {
      const str = synonym.trim();
      setSynonym('');
      if (str && !synonyms.includes(str)) {
        setSynonyms([...synonyms, keyword]);
      }
    } else {
      const str = exclusion.trim();
      setExclusion('');
      if (str && !exclusions.includes(str)) {
        setExclusions([...exclusions, keyword]);
      }
    }
  };

  const removeSynonym = (synonym: string) => {
    setSynonyms(synonyms.filter((s) => s !== synonym));
  };

  const removeExclusion = (exclusion: string) => {
    setExclusions(exclusions.filter((e) => e !== exclusion));
  };

  const handleAdd = async () => {
    if (keyword.trim() === '') {
      return;
    }

    if (defaultData) {
      const res = await TeambinderAiService.modifyAiRealtimeKeyword({
        keywordId: defaultData.keywordId,
        requestBody: {
          keyword: keyword,
          synonyms,
          excludeKeywords: exclusions,
        },
      });

      info({
        title: '수정되었습니다.',
        okText: '확인',
        onOk: () => {
          onClose();
        },
      });
    } else {
      const res = await TeambinderAiService.addAiRealtimeKeyword({
        requestBody: {
          keyword: keyword,
          synonyms,
          excludeKeywords: exclusions,
        },
      });
      window.location.href = `/app/realtime?id=${res.data}`;
    }
  };

  return (
    <Modal
      open
      onCancel={onClose}
      footer={null}
      title={defaultData ? '키워드 수정' : '키워드 추가'}
    >
      <div className="addRealtimeKeywordModal">
        <div className="inputGroup">
          <label>키워드</label>
          <Input
            placeholder="키워드를 입력해 주세요."
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
        </div>
        <div className="inputGroup">
          <div className="option">
            <label>세부옵션(선택사항)</label>
            <button onClick={() => setIsDescModalOpen(true)}>세부설명</button>
          </div>
          <label>동의어</label>
          <div className="synonymInput">
            <Input
              placeholder="동의어를 입력하세요."
              value={synonym}
              onChange={(e) => setSynonym(e.target.value)}
              onKeyDown={(e) => {
                e.key === 'Enter' &&
                  !e.nativeEvent.isComposing &&
                  addSynonymOrExclusion('synonym', synonym);
              }}
            />
            <button
              className="addSynonym"
              onClick={() => {
                addSynonymOrExclusion('synonym', synonym);
              }}
            >
              <IcoAdd width={24} height={24} fill="#4403B5" />
            </button>
          </div>
          <div className="synonymList">
            {synonyms.map((synonym) => (
              <span className="synonym" key={synonym}>
                {synonym}
                <IcoClose
                  fontSize={20}
                  style={{ cursor: 'pointer' }}
                  onClick={() => removeSynonym(synonym)}
                />
              </span>
            ))}
          </div>
        </div>
        <div className="inputGroup">
          <label>제외어</label>
          <div className="exclusionInput">
            <Input
              placeholder="제외어를 입력하세요."
              value={exclusion}
              onChange={(e) => setExclusion(e.target.value)}
              onKeyDown={(e) => {
                e.key === 'Enter' &&
                  !e.nativeEvent.isComposing &&
                  addSynonymOrExclusion('exclusion', exclusion);
              }}
            />
            <button
              className="addExclusion"
              onClick={() => {
                addSynonymOrExclusion('exclusion', exclusion);
              }}
            >
              <IcoAdd width={24} height={24} fill="#4403B5" />
            </button>
          </div>
          <div className="exclusionList">
            {exclusions.map((exclusion) => (
              <span
                className="exclusion"
                key={exclusion}
                onClick={() => removeExclusion(exclusion)}
              >
                {exclusion}
                <IcoClose
                  fontSize={20}
                  style={{ cursor: 'pointer' }}
                  onClick={() => removeExclusion(exclusion)}
                />
              </span>
            ))}
          </div>
        </div>
        <div>
          <button className="add" onClick={handleAdd}>
            {defaultData ? '키워드 수정' : '키워드 추가'}
          </button>
        </div>
      </div>
      {isDescModalOpen && (
        <AddRealtimeKeywordDescModal
          onClose={() => setIsDescModalOpen(false)}
        />
      )}
    </Modal>
  );
};

export default RealtimeKeywordModal;
