import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoAi = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.096 3c.28 0 .53.18.618.447l.71 2.142 2.131.714a.654.654 0 0 1 0 1.24l-2.131.714-.71 2.142a.651.651 0 0 1-1.235 0l-.71-2.142-2.131-.714a.654.654 0 0 1 0-1.24l2.13-.714.711-2.142A.651.651 0 0 1 18.096 3ZM18.096 12.372c.6 0 1.085.488 1.085 1.09v5.448c0 .602-.486 1.09-1.085 1.09a1.087 1.087 0 0 1-1.084-1.09v-5.449c0-.601.486-1.09 1.084-1.09ZM7.415 6.626c.132-.469.558-.793 1.043-.793h1.928c.485 0 .911.324 1.043.793l3.373 11.987a1.084 1.084 0 1 1-2.087.593L9.566 8.014h-.287l-3.15 11.194a1.084 1.084 0 1 1-2.087-.594L7.415 6.626Z"
      fill="#757575"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.085 15.64c0-.601.485-1.089 1.084-1.089h6.506c.599 0 1.084.488 1.084 1.09 0 .602-.485 1.09-1.084 1.09H6.169a1.087 1.087 0 0 1-1.084-1.09Z"
      fill="#757575"
    />
  </svg>
);
export default SvgIcoAi;
