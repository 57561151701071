import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m5.334 15.833-1.167-1.166L8.834 10 4.167 5.333l1.167-1.166L10 8.833l4.667-4.666 1.167 1.166L11.167 10l4.667 4.667-1.167 1.166L10 11.167l-4.666 4.666Z"
      fill="#4403B5"
    />
  </svg>
);
export default SvgIcoClose;
