import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoRealtimeEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ico-realtime-edit_svg__a)">
      <path
        d="M2.594 9.214a.542.542 0 0 0 .08-.006l2.253-.395a.131.131 0 0 0 .07-.038l5.678-5.677a.135.135 0 0 0 .04-.094.134.134 0 0 0-.04-.095L8.45.682a.133.133 0 0 0-.095-.039.133.133 0 0 0-.095.039L2.582 6.359a.136.136 0 0 0-.038.071L2.15 8.683a.449.449 0 0 0 .126.399c.089.086.2.132.319.132Zm.903-2.335 4.857-4.856.982.981L4.478 7.86l-1.19.21.209-1.191Zm7.431 3.46H1.071a.428.428 0 0 0-.428.43v.481c0 .06.048.107.107.107h10.5a.108.108 0 0 0 .107-.107v-.482a.428.428 0 0 0-.429-.429Z"
        fill="#57616B"
      />
    </g>
    <defs>
      <clipPath id="ico-realtime-edit_svg__a">
        <path fill="#fff" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIcoRealtimeEdit;
