import { TeambinderAiService } from '@src/api';
import { IcoAdd, IcoClose, IcoCloseCircle } from '@src/assets/svg';
import { Input, Modal } from 'antd';
import { useState } from 'react';
import { useNavigate, useNavigation } from 'react-router-dom';

interface Prop {
  onClose: () => void;
}
const AddRealtimeKeywordDescModal = ({ onClose }: Prop) => {
  return (
    <Modal open onCancel={onClose} footer={null} title="세부설명">
      <div className="desc-modal">
        <h2>동의어 (Synonyms)</h2>
        <p>
          개념: 같은 의미를 가지는 여러 표현을 말합니다.
          <br />
          목적: 서로 다른 단어로 표현되더라도 동일한 의미를 가진 데이터를 모두
          포함하여 검색하거나 분석하기 위해 사용됩니다.
          <br />
          <ul>
            <li>예시: "맥도날드"의 동의어: 맥날, 맥디, McDonald's, 맥도</li>
            <li>"감자튀김"의 동의어: 프렌치프라이, 프라이, 감튀</li>
          </ul>
        </p>
        <h2>제외어 (Exclude Terms)</h2>
        <p>
          개념: 특정 키워드가 포함되지 않은 데이터를 필터링하는 조건을 말합니다.
          <br />
          목적: 불필요하거나 원하지 않는 데이터를 배제하고 정확도를 높이기 위해
          사용됩니다.
          <br />
          예시:
          <ul>
            <li>제외어: "맥도날드"를 제외한 문장</li>
            <li>"버거킹에서 신메뉴를 출시했다." → 포함</li>
            <li>"맥도날드에서 프로모션이 진행 중이다." → 제외</li>
          </ul>
        </p>
      </div>
    </Modal>
  );
};

export default AddRealtimeKeywordDescModal;
