import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoShield = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 402 402"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={37.688}
      y={75.375}
      width={301.5}
      height={251.25}
      rx={5}
      fill="#F1F3F5"
    />
    <path
      d="M78.516 125.625a9.422 9.422 0 1 0 0-18.843 9.422 9.422 0 0 0 0 18.843ZM109.922 125.625a9.422 9.422 0 1 0 0-18.844 9.422 9.422 0 0 0 0 18.844ZM141.328 125.625a9.422 9.422 0 1 0 0-18.844 9.422 9.422 0 0 0 0 18.844Z"
      fill="#fff"
    />
    <path
      d="M183.762 135.947c.171-1.33 1.207-2.273 2.537-2.445 6.846-.887 27.941-3.833 42.351-8.196 15.137-4.583 37.652-15.199 43.276-17.894a3.01 3.01 0 0 1 2.617 0c5.623 2.695 28.139 13.311 43.276 17.894 14.41 4.363 35.505 7.309 42.351 8.196 1.33.172 2.365 1.115 2.536 2.445 2.299 17.891 14.263 141.359-88.625 171.588a3.068 3.068 0 0 1-1.694 0c-102.888-30.229-90.924-153.697-88.625-171.588Z"
      fill="#C8D1EA"
    />
    <path
      d="M208.375 154.354c.158-1.333 1.198-2.31 2.528-2.492 5.513-.753 20.016-2.916 30.046-6.062 10.626-3.332 26.273-10.916 30.93-13.218a3.052 3.052 0 0 1 2.71 0c4.658 2.302 20.305 9.886 30.931 13.218 10.03 3.146 24.533 5.309 30.045 6.062 1.331.182 2.371 1.159 2.529 2.492 1.808 15.263 9.257 105.533-63.987 128.039a3.035 3.035 0 0 1-1.746 0c-73.243-22.506-65.794-112.776-63.986-128.039Z"
      fill="#fff"
    />
    <path
      d="M208.375 154.354c.158-1.334 1.198-2.31 2.528-2.492 5.513-.753 20.016-2.916 30.046-6.062 10.626-3.332 26.273-10.916 30.93-13.219a3.059 3.059 0 0 1 2.71 0c4.658 2.303 20.305 9.887 30.931 13.219 10.03 3.146 24.533 5.309 30.045 6.062 1.331.182 2.371 1.158 2.529 2.492 1.808 15.262 9.257 105.532-63.987 128.038a3.035 3.035 0 0 1-1.746 0c-73.243-22.506-65.794-112.776-63.986-128.038Z"
      stroke="#F1F3F5"
      strokeWidth={2}
    />
  </svg>
);
export default SvgIcoShield;
