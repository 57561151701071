import React, { lazy } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { FallbackProps } from 'react-error-boundary';

import { Loading } from '@components/shared';
import PageError from '@components/shared/PageError';
import AsyncBoundary from '@src/components/shared/AsyncBoundary';
import { APP_HOME_PATH, MAIN_PATH } from '@src/constants/constant';
import SettingsMy from '@src/views/setting/SettingMy';

const KeywordNews = lazy(() => import(`@views/keywordNews/KeywordNews`));
const RealtimeKeyword = lazy(
  () => import('@views/realtimeKeyword/RealtimeKeyword'),
);

export const AppViews = () => {
  const location = useLocation();

  return (
    <AsyncBoundary
      ErrorFallback={(fallbackProps: FallbackProps) => (
        <PageError fallbackProps={fallbackProps} />
      )}
      SuspenseFallback={<Loading cover="content" />}
      resetKeys={[location.pathname]}
    >
      <Routes>
        <Route path="/realtime/*" element={<RealtimeKeyword />} />
        <Route path="/my" element={<SettingsMy />} />
        <Route path="/*" element={<KeywordNews />} />
      </Routes>
    </AsyncBoundary>
  );
};

export default React.memo(AppViews);
