import { TeambinderApiService } from '@src/api';
import { useQuery } from '@tanstack/react-query';
import { Button, Progress } from 'antd';
import dayjs from 'dayjs';
import { CSSProperties } from 'react';
import { useNavigate } from 'react-router-dom';

interface Prop {
  containerStyle?: CSSProperties;
  showTalkQuota?: boolean;
}

const SettingQuotaPane = (prop: Prop) => {
  const { containerStyle, showTalkQuota = true } = prop;
  const navigate = useNavigate();
  const { data: userResponse, refetch: refetchSetting } = useQuery(
    ['member'],
    TeambinderApiService.getPersonalSettings,
  );
  const { data: currentSubscription } = useQuery(
    ['current-subscription'],
    () => TeambinderApiService.getLatestSubscription({ q: 'latest' }),
    { refetchInterval: 3000 },
  );

  // TODO: openapi 스펙 이상함
  const currentPlan =
    // @ts-ignore
    currentSubscription?.data?.plan?.subscriptionPackage?.subscriptionPlan;

  return (
    <div style={containerStyle}>
      <div>
        <div
          style={{
            width: '100%',
            maxWidth: '460px',
            marginBottom: '16px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>현재 키워드 설정 숫자</span>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {Math.min(
                userResponse?.data?.quota?.keyword?.used || 0,
                userResponse?.data?.quota?.keyword?.total || 0,
              )}
              /{userResponse?.data?.quota?.keyword?.total}개
              {['FREE', 'BASIC'].includes(currentPlan) && (
                <Button
                  onClick={() => {
                    navigate('/app/my?tab=2');
                  }}
                  type="primary"
                  size="small"
                  style={{
                    backgroundColor: '#6268DB',
                    border: '#6268DB',
                    fontSize: '10px',
                    borderRadius: '4px',
                    width: '62px',
                    height: '24px',
                    padding: '2px',
                    marginLeft: '10px',
                  }}
                >
                  업그레이드
                </Button>
              )}
            </span>
          </div>
          <Progress
            strokeColor="rgb(48, 197, 134)"
            percent={
              ((userResponse?.data?.quota?.keyword?.used || 0) /
                (userResponse?.data?.quota?.keyword?.total || 1)) *
              100
            }
            showInfo={false}
            width={300}
          />
        </div>
      </div>
      {showTalkQuota && (
        <div style={{ marginBottom: '16px' }}>
          <div
            style={{
              width: '100%',
              maxWidth: '460px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span>현재 알림톡 발송 숫자</span>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {userResponse?.data?.quota?.notification?.total === 999 ? (
                  <span>무제한</span>
                ) : (
                  <>
                    {Math.min(
                      userResponse?.data?.quota?.notification?.used || 0,
                      userResponse?.data?.quota?.notification?.total || 0,
                    )}
                    /{userResponse?.data?.quota?.notification?.total}개
                    {currentPlan !== 'PREMIUM' && (
                      <Button
                        onClick={() => {
                          navigate('/app/my?tab=2');
                        }}
                        type="primary"
                        size="small"
                        style={{
                          backgroundColor: '#6268DB',
                          border: '#6268DB',
                          fontSize: '10px',
                          borderRadius: '4px',
                          width: '62px',
                          height: '24px',
                          padding: '2px',
                          marginLeft: '10px',
                        }}
                      >
                        업그레이드
                      </Button>
                    )}
                  </>
                )}
              </span>
            </div>
            <Progress
              strokeColor="rgb(48, 197, 134)"
              percent={
                userResponse?.data?.quota?.notification?.total === 999
                  ? 0
                  : ((userResponse?.data?.quota?.notification?.used || 0) /
                      (userResponse?.data?.quota?.notification?.total || 1)) *
                    100
              }
              showInfo={false}
              width={300}
            />
            <div
              style={{
                fontSize: '13px',
                color: '#6B7280',
                textAlign: 'end',
                wordBreak: 'keep-all',
              }}
            >
              알림톡 발송 제한은 한달마다 초기화됩니다. <br />
              {userResponse?.data?.quota?.resetDate &&
                `(다음 초기화 일자: ${dayjs(
                  userResponse?.data?.quota?.resetDate,
                ).format('M월 D일')})`}
            </div>
          </div>
        </div>
      )}
      <div>
        <div
          style={{
            width: '100%',
            maxWidth: '460px',
            marginBottom: '16px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>AI 컨텐츠 분석</span>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {Math.min(
                userResponse?.data?.quota?.aiAnalysis?.used || 0,
                userResponse?.data?.quota?.aiAnalysis?.total || 0,
              )}
              /{userResponse?.data?.quota?.aiAnalysis?.total}개
              {currentPlan !== 'ENTERPRISE' && (
                <Button
                  onClick={() => {
                    navigate('/app/my?tab=2');
                  }}
                  type="primary"
                  size="small"
                  style={{
                    backgroundColor: '#6268DB',
                    border: '#6268DB',
                    fontSize: '10px',
                    borderRadius: '4px',
                    width: '62px',
                    height: '24px',
                    padding: '2px',
                    marginLeft: '10px',
                  }}
                >
                  업그레이드
                </Button>
              )}
            </span>
          </div>
          <Progress
            strokeColor="rgb(48, 197, 134)"
            percent={
              ((userResponse?.data?.quota?.aiAnalysis?.used || 0) /
                (userResponse?.data?.quota?.aiAnalysis?.total || 1)) *
              100
            }
            showInfo={false}
            width={300}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingQuotaPane;
