import * as React from 'react';
import { SVGProps } from 'react';
const SvgIcoRealtimeRemove = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ico-realtime-remove_svg__a)">
      <path
        d="M3.965 1.607h-.108a.107.107 0 0 0 .108-.107v.107h4.071V1.5c0 .06.048.107.107.107h-.107v.965H9V1.5a.858.858 0 0 0-.857-.857H3.857A.858.858 0 0 0 3 1.5v1.072h.965v-.965Zm6.75.965H1.286A.428.428 0 0 0 .857 3v.429c0 .059.049.107.108.107h.808l.331 7.004c.022.457.4.817.856.817h6.08a.856.856 0 0 0 .856-.817l.331-7.004h.809a.107.107 0 0 0 .107-.107V3a.428.428 0 0 0-.428-.428Zm-1.778 7.821H3.063L2.74 3.536h6.522l-.324 6.857Z"
        fill="#57616B"
      />
    </g>
    <defs>
      <clipPath id="ico-realtime-remove_svg__a">
        <path fill="#fff" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgIcoRealtimeRemove;
