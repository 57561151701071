import { NavigationTypes } from '@src/types/types';
import Scrollbars from 'react-custom-scrollbars';
import BinderKeywordList from './BinderKeywordList';
import RealtimeKeywordList from './RealtimeKeywordList';
import SettingQuotaPane from '@src/views/setting/SettingQuotaPane';
import AsyncBoundary from '@src/components/shared/AsyncBoundary';
import PageError from '@src/components/shared/PageError';
import { FallbackProps } from 'react-error-boundary';
import { Loading } from '@src/components/shared';

export const MenuContent = () => {
  return (
    <Scrollbars
      style={{
        height: '100%',
        overflowX: 'hidden',
      }}
    >
      <AsyncBoundary
        ErrorFallback={(fallbackProps: FallbackProps) => (
          <PageError fallbackProps={fallbackProps} />
        )}
        SuspenseFallback={<Loading cover="content" />}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            height: '100%',
            paddingBottom: '40px',
          }}
        >
          <div>
            <RealtimeKeywordList />
            <BinderKeywordList />
          </div>
          <div>
            <SettingQuotaPane
              containerStyle={{ padding: '14px' }}
              showTalkQuota={false}
            />
          </div>
        </div>
      </AsyncBoundary>
    </Scrollbars>
  );
};
