import React, { useEffect } from 'react';
import { Grid, Layout } from 'antd';
import { useAtom, useAtomValue } from 'jotai';

import { KAKAO_REDIRECT_URL, SIDE_NAV_WIDTH } from '@src/constants/constant';
import utils from '@src/utils/utils';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { useLocation, useSearchParams } from 'react-router-dom';

import { Loading } from '@components/shared';
import {
  Footer,
  HeaderNav,
  MobileNav,
  PageHeader,
  SideNav,
} from '@components/layout';
import AppRoutes from '@src/layouts/routes/AppRoutes';
import navigationConfig from '@src/layouts/navigation/navigation';
import { NavigationTypes } from '@src/types/types';

import { themeNavCollapsedAtom } from '@src/store/theme';
import { useChannelTalk } from '@hooks/channelTalk/useChannelTalk';
import { authAtom } from '@src/store/auth';
import _ from 'lodash';
import { TeambinderApiService } from '@src/api';

const subHeaderUrl = ['/analysis'];
export const AppLayout = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [authInfo, setAuthInfo] = useAtom(authAtom);
  if (!authInfo || _.isEmpty(authInfo)) {
    const authRedirecrtUrl = KAKAO_REDIRECT_URL;
    setTimeout(() => {
      window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=7fa35d903c130394963af980d96edd38&redirect_uri=${authRedirecrtUrl}&response_type=code&prompt=login`;
    }, 500);
  }
  useEffect(() => {
    TeambinderApiService.getBinderList().then((r) => {
      if (location.pathname === '/app' && r?.data?.[0]?.binderId) {
        setSearchParams({
          ...Object.fromEntries(searchParams.entries()),
          id: r.data[0]?.binderId,
        });
      }
    });
  }, []);

  const navCollapsed = useAtomValue(themeNavCollapsedAtom);
  const { pathname } = useLocation();
  const channelTalk = useChannelTalk();
  const currentRouteInfo = utils.getRouteInfo(
    [...navigationConfig],
    pathname,
  ) as NavigationTypes;
  const isMobile = utils.isMobile();
  const getLayoutGutter = () => {
    if (isMobile) {
      return 0;
    }
    return SIDE_NAV_WIDTH;
  };

  const { status } = useThemeSwitcher();

  const isSubHeaderActive = () => {
    const path = location.pathname;
    let flag = true;
    subHeaderUrl?.map((item) => {
      if (path.includes(item) == false) {
        flag = false;
        return;
      }
    });

    return flag;
  };

  useEffect(() => {
    channelTalk.boot();

    return () => {
      channelTalk.shutdown();
    };
  }, []);

  if (status === 'loading') {
    return <Loading cover="page" />;
  }

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      <Layout className="app-container">
        {!isMobile ? <SideNav /> : null}
        <Layout
          className="app-layout"
          style={{ paddingLeft: getLayoutGutter() }}
        >
          <div className={'app-content-header'}>
            <PageHeader
              display={currentRouteInfo?.breadcrumb}
              title={currentRouteInfo?.title}
            />
          </div>
          <div
            className={`app-sub-content ${
              isSubHeaderActive() ? '' : 'no-sub-header'
            }`}
          >
            <AppRoutes />
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav routeInfo={currentRouteInfo} />}
    </Layout>
  );
};

export default React.memo(AppLayout);
