import React, { useEffect, useState } from 'react';
import { Input, Menu, Modal, Popover } from 'antd';
import { useAtom, useSetAtom } from 'jotai';
import {
  Link,
  useNavigate,
  useNavigation,
  useSearchParams,
} from 'react-router-dom';

import utils from '@src/utils/utils';
import { themeCurrentThemeAtom, themeMobileNavAtom } from '@src/store/theme';
import EllipsisDropdown from '@components/shared/EllipsisDropdown';
import { AddCircleOutline, IcoInbox } from '@src/assets/svg';
import { useMutation } from '@tanstack/react-query';
import { BinderEntity, TeambinderApiService } from '@src/api';
import useLogout from '@src/hooks/auth/useLogout';
import { KAKAO_REDIRECT_URL } from '@src/constants/constant';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

const { confirm } = Modal;

const BinderKeywordList = () => {
  const logout = useLogout();
  const setMobileNav = useSetAtom(themeMobileNavAtom);
  const [currentTheme, setCurrentTheme] = useAtom(themeCurrentThemeAtom);
  const isMobile = utils.isMobile();
  const closeMobileNav = () => {
    if (isMobile) {
      setMobileNav(false);
    }
  };

  const [binders, setBinders] = useState<BinderEntity[]>([]);
  const fetchBinders = async (replaceUrl = false) => {
    try {
      const res = await TeambinderApiService.getBinderList();
      setBinders(res.data || []);
      if (replaceUrl) {
        if (res.data?.[0]?.binderId) {
          navigate(`/app?id=${res.data[0]?.binderId}`, { replace: true });
        } else {
          navigate('/app', { replace: true });
        }
      }
    } catch (e) {
      if ([401, 500].includes((e as any).status)) {
        logout();
        const authRedirecrtUrl = KAKAO_REDIRECT_URL;
        window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=7fa35d903c130394963af980d96edd38&redirect_uri=${authRedirecrtUrl}&response_type=code&prompt=login`;
      }
    }
  };

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const binderId = searchParams.get('id') || binders?.[0]?.binderId;
  useEffect(() => {
    fetchBinders();
  }, []);

  useEffect(() => {
    if (
      location.pathname === '/app' &&
      searchParams.get('id') === null &&
      binders.length > 0
    ) {
      navigate(`/app?id=${binders[0].binderId}`, { replace: true });
    }
  }, [location.href, searchParams.get('id'), binders]);

  const removeKeywordList = (index: number) => {
    confirm({
      title: '정말 삭제하시겠습니까?',
      async onOk() {
        if (binders?.[index]?.binderId === undefined) {
          return;
        }
        await TeambinderApiService.removeBinder({
          binderId: binders?.[index]?.binderId || '',
        });
        fetchBinders(true);
      },
      onCancel() {
        return null;
      },
      okText: '삭제',
      cancelText: '취소',
    });
  };
  const renameKeywordList = (index: number) => {
    confirm({
      title: '이름 수정',
      content: (
        <Input
          id="rename-keyword"
          defaultValue={binders?.[index]?.name || ''}
        />
      ),
      async onOk() {
        const newName = (
          document.getElementById('rename-keyword') as HTMLInputElement
        ).value;
        if (newName === '') {
          return;
        }
        const binder = binders?.[index];
        if (!binder?.binderId) {
          return;
        }
        await TeambinderApiService.editBinder({
          binderId: binder.binderId,
          name: newName,
        });
        fetchBinders();
      },
      onCancel() {
        return null;
      },
      okText: '변경',
      cancelText: '취소',
    });
  };

  const addNewList = () => {
    confirm({
      title: '바인더 추가',
      content: <Input id="new-keyword" />,
      async onOk() {
        const newName = (
          document.getElementById('new-keyword') as HTMLInputElement
        ).value;
        if (newName === '') {
          return;
        }
        await TeambinderApiService.addBinder({ name: newName });
        fetchBinders(true);
      },
      onCancel() {
        return null;
      },
      okText: '추가',
      cancelText: '취소',
    });
  };

  const { mutate: reorderKeyword } = useMutation(
    TeambinderApiService.reorderBinder,
    {
      onSuccess: () => fetchBinders(),
    },
  );

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    let targetItemId, prevItemId;
    if (source.index < destination.index) {
      targetItemId = binders[source.index]?.binderId;
      prevItemId = binders[destination.index]?.binderId;
    } else {
      targetItemId = binders[source.index]?.binderId;
      prevItemId =
        destination.index === 0 ? '' : binders[destination.index - 1]?.binderId;
    }
    if (!targetItemId) {
      return;
    }

    // 1. 먼저 로컬 상태를 변경
    const newList = [...binders];
    const [destList] = newList.splice(source.index, 1);
    newList.splice(destination.index, 0, destList);
    setBinders(newList);

    // 2. 서버에 변경사항 반영
    reorderKeyword({
      targetBinderId: targetItemId,
      prevBinderId: prevItemId,
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
      }}
    >
      <button
        className="title"
        style={{
          border: 'none',
          backgroundColor: 'inherit',
          fontSize: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '8px',
          paddingRight: '17px',
          paddingLeft: '12px',
          fontWeight: 700,
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <IcoInbox />
          Inbox
        </div>{' '}
        <AddCircleOutline
          onClick={addNewList}
          style={{
            cursor: 'pointer',
          }}
        />
      </button>
      <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
        <Droppable droppableId="binders">
          {(provided) => {
            return (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  minHeight: `${binders.length * 40}px`,
                }}
              >
                {binders.map((item, index) => {
                  const isActive =
                    item.binderId === binderId && location.pathname === '/app';
                  return (
                    <Draggable
                      draggableId={item.binderId!}
                      index={index}
                      key={item.binderId}
                    >
                      {(_provided, snapshot) => (
                        <div
                          ref={_provided.innerRef}
                          {..._provided.draggableProps}
                          {..._provided.dragHandleProps}
                        >
                          <Popover
                            mouseEnterDelay={5}
                            content={
                              <div>
                                마우스를 드래그하여 키워드 순서를 변경할 수
                                있습니다.
                              </div>
                            }
                            trigger="hover"
                          >
                            <div
                              className=""
                              key={item.name}
                              style={{
                                width: '100%',
                                fontSize: '16px',
                                padding: '4px 8px 4px 30px',
                                cursor: 'pointer',
                                display: 'flex',
                                fontWeight: 500,
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                border: 'none',
                                backgroundColor:
                                  isActive || snapshot.isDragging
                                    ? '#f3f3f3'
                                    : 'inherit',
                                borderRight: isActive
                                  ? '3px solid #4403b5'
                                  : '3px solid transparent',
                                color:
                                  isActive && currentTheme === 'dark'
                                    ? 'black'
                                    : 'inherit',
                              }}
                              role="presentation"
                              onClick={() => {
                                if (item.binderId) {
                                  navigate(`/app?id=${item.binderId}`);
                                  closeMobileNav();
                                }
                              }}
                            >
                              {item.name}
                              <EllipsisDropdown
                                menu={
                                  <Menu
                                    items={[
                                      {
                                        label: '이름 수정',
                                        key: 'refresh',
                                        icon: null,
                                        onClick: (e) => {
                                          e.domEvent.stopPropagation();
                                          renameKeywordList(index);
                                        },
                                      },
                                      {
                                        label: '삭제',
                                        key: 'remove',
                                        icon: null,
                                        onClick: (e) => {
                                          e.domEvent.stopPropagation();
                                          removeKeywordList(index);
                                        },
                                      },
                                    ]}
                                  />
                                }
                              />
                            </div>
                          </Popover>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                <div style={{ display: 'none' }}>{provided.placeholder}</div>
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default BinderKeywordList;
