/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddAiRealtimeKeywordPayload } from '../models/AddAiRealtimeKeywordPayload';
import type { BasicResponseAiRealtimeKeywordItem } from '../models/BasicResponseAiRealtimeKeywordItem';
import type { BasicResponseListAiRealtimeKeywordItem } from '../models/BasicResponseListAiRealtimeKeywordItem';
import type { BasicResponseListAnalysisResultItem } from '../models/BasicResponseListAnalysisResultItem';
import type { BasicResponseReportContent } from '../models/BasicResponseReportContent';
import type { BasicResponseUUID } from '../models/BasicResponseUUID';
import type { BasicResponseVoid } from '../models/BasicResponseVoid';
import type { ModifyAiRealtimeKeywordPayload } from '../models/ModifyAiRealtimeKeywordPayload';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TeambinderAiService {

    /**
     * AI 실시간 감지 키워드 조회
     * @returns BasicResponseAiRealtimeKeywordItem OK
     * @throws ApiError
     */
    public static getAiRealtimeKeyword({
        keywordId,
    }: {
        /**
         * 키워드 ID
         */
        keywordId: string,
    }): CancelablePromise<BasicResponseAiRealtimeKeywordItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binder-app/ai-realtime/keywords/{keyword-id}',
            path: {
                'keyword-id': keywordId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * AI 실시간 감지 키워드 수정
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static modifyAiRealtimeKeyword({
        keywordId,
        requestBody,
    }: {
        /**
         * 키워드 ID
         */
        keywordId: string,
        requestBody: ModifyAiRealtimeKeywordPayload,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/binder-app/ai-realtime/keywords/{keyword-id}',
            path: {
                'keyword-id': keywordId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * AI 실시간 감지 키워드 삭제
     * @returns BasicResponseVoid OK
     * @throws ApiError
     */
    public static deleteAiRealtimeKeyword({
        keywordId,
    }: {
        /**
         * 키워드 ID
         */
        keywordId: string,
    }): CancelablePromise<BasicResponseVoid> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/binder-app/ai-realtime/keywords/{keyword-id}',
            path: {
                'keyword-id': keywordId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * AI 실시간 감지 키워드 리스트 조회
     * @returns BasicResponseListAiRealtimeKeywordItem OK
     * @throws ApiError
     */
    public static getAllAiRealtimeKeyword(): CancelablePromise<BasicResponseListAiRealtimeKeywordItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binder-app/ai-realtime/keywords',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * AI 실시간 감지 키워드 등록
     * @returns BasicResponseUUID OK
     * @throws ApiError
     */
    public static addAiRealtimeKeyword({
        requestBody,
    }: {
        requestBody: AddAiRealtimeKeywordPayload,
    }): CancelablePromise<BasicResponseUUID> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/binder-app/ai-realtime/keywords',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * AI 실시간 감지 리포트 조회
     * @returns BasicResponseReportContent OK
     * @throws ApiError
     */
    public static getAiRealtimeContentReport({
        keywordId,
    }: {
        /**
         * 키워드 ID
         */
        keywordId: string,
    }): CancelablePromise<BasicResponseReportContent> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binder-app/ai-realtime/report/{keyword-id}',
            path: {
                'keyword-id': keywordId,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * AI 실시간 감지 리포트 컨텐츠 리스트 조회
     * @returns BasicResponseListAnalysisResultItem OK
     * @throws ApiError
     */
    public static getAiRealtimeContents({
        keywordId,
        display,
        start,
    }: {
        /**
         * 키워드 ID
         */
        keywordId: string,
        /**
         * 페이지 사이즈 (1 ~ 100 사이의 값)
         */
        display?: number,
        /**
         * 페이지 번호 (1부터 시작)
         */
        start?: number,
    }): CancelablePromise<BasicResponseListAnalysisResultItem> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/binder-app/ai-realtime/report/{keyword-id}/contents',
            path: {
                'keyword-id': keywordId,
            },
            query: {
                'display': display,
                'start': start,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
